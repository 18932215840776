@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

.landing-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(#f15f4b 0%, #ef4e39 100%);

  .nav-header {
    padding: 1.5rem 0;
  }

  .nav-header__menu-container li a,
  .nav__hamburger,
  .footer a {
    color: $color-landing-text;

    &:hover {
      color: $color-landing-hover;
    }
  }

  .logo {
    color: $color-landing-text;
  }

  .section-wrapper {
    margin-bottom: 1rem;
  }
}

.landing {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  margin: 0 2rem;
  color: $color-landing-text;
}

.landing__image-frame {
  position: relative;
  text-align: center;
  margin-top: 1rem;
}

.landing__subtitle {
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 2px;
  line-height: 1.3;
}

.landing__subtitle--rotated {
  @extend .landing__subtitle;

  text-align: left;
  position: relative;
  transform-origin: bottom left;
  transform: translateX(100%) rotate(90deg);
  position: absolute;
  top: 15px;
  right: -20px;
}

.landing__image {
  margin: 2rem 0 3rem;

  img {
    height: 275px;
  }
}

// https://codepen.io/Ajiva/pen/KKwWxYZ
.landing__button {
  display: block;
  position: relative;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color-landing-text;
  border: 1px dotted $color-landing-text;
  transition: all 0.2s ease;
  text-decoration: none;

  &:before,
  &:after {
    content: "MISSION";
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 0;
    width: 100%;
    -webkit-clip: rect(0px, 0px, 0px, 0px);
    clip: rect(0px, 0px, 0px, 0px);
    background: $color-accent-tertiary;
    color: $color-primary;
  }

  &:before {
    left: -3px;
    top: -2px;
    text-shadow: 2px 0 #fff;
    box-shadow: 2px 0 #fff;
  }

  &:after {
    left: 2px;
    bottom: -2px;
    text-shadow: -1px 0 #fff;
    box-shadow: -1px 0 #fff;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.8);
    color: $color-primary;
    border-color: transparent;
  }

  &:hover:before {
    animation: glitch-button 1.5s infinite linear alternate-reverse;
  }

  &:hover:after {
    animation: glitch-button 2s infinite linear alternate;
  }
}

@keyframes glitch-button {
  0% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  5.88235% {
    clip: rect(0px, 600px, 0px, 0px);
  }
  11.76471% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  17.64706% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  23.52941% {
    clip: rect(100px, 600px, 100px, 0px);
  }
  29.41176% {
    clip: rect(0px, 600px, 600px, 0px);
  }
  35.29412% {
    clip: rect(100px, 600px, 0px, 0px);
  }
  41.17647% {
    clip: rect(0px, 600px, 600px, 0px);
  }
  47.05882% {
    clip: rect(100px, 600px, 0px, 0px);
  }
  52.94118% {
    clip: rect(-3px, 600px, 0px, 0px);
  }
  58.82353% {
    clip: rect(100px, 450px, 100px, 0px);
  }
  64.70588% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  70.58824% {
    clip: rect(100px, 450px, 100px, 0px);
  }
  76.47059% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  82.35294% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  88.23529% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  94.11765% {
    clip: rect(0px, 450px, 0px, 0px);
  }
  100% {
    clip: rect(0px, 450px, 0px, 0px);
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .landing__subtitle--rotated {
    top: 20px;
    right: 0;
  }

  .landing__image {
    margin: 2rem 0;

    img {
      height: 250px;
    }
  }

  .landing__button {
    font-size: 1.2rem;
  }
}
