@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

.footer {
  z-index: 9;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1.5rem 2rem;

  a {
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out;
    font-size: 1.125rem;
    font-weight: 700;

    &:hover {
      color: $color-primary-hover;
    }
  }

  .socialmedia {
    margin-left: 1.5rem;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .footer a {
    font-size: 1rem;
  }
}
