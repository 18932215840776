@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */
@use "./proposalcard";
@use "./proposaldetails";

/* Shared NFT image styles */

%image-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

%thumb {
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

%preview-file {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border: none;
}

%no-image-available {
  display: "flex";
  justify-content: center;
  align-items: center;
}

/* NFTPreview (viewed in CreateNFTTributePropoosal form) */

.nft-preview {
  display: flex;
  flex: 1 1 100%;
  background-color: $color-background-primary;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 2px 0 $color-shadow-primary;
  width: 100%;
  max-width: 41rem;
  box-sizing: border-box;
  min-height: 10rem;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.nft-preview__info-column {
  flex: 0 1 50%;
  word-wrap: break-word;
  padding-left: 2rem;
  box-sizing: border-box;
}

.nft-preview__title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.nft-preview__subtitle {
  font-size: 1rem;
  margin-bottom: 4rem;
}

.nft-preview__text {
  font-size: 1rem;

  label {
    font-weight: 300;
  }

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
}

.nft-preview__link {
  color: $color-secondary;
  text-decoration: none;

  svg {
    margin-left: 12px;
    transform: translateY(1px);
  }
}

.nft-preview__image-column {
  flex: 0 1 50%;
  min-width: 50%;
}

.nft-preview__image-container {
  @extend %image-container;

  height: 250px;

  .thumb {
    @extend %thumb;
  }

  .preview-file {
    @extend %preview-file;
  }

  .no-image-available {
    @extend %no-image-available;

    font-size: 0.8rem;
  }
}

.nft-preview__close-button {
  @extend %button-base;

  position: absolute;
  right: 0.5rem;
  top: 0.3rem;
  color: $color-secondary;
  width: 10px;
  opacity: 0.8;
}

@media only screen and (max-width: em($bp-sm)) {
  .nft-preview {
    flex-direction: column;
  }

  .nft-preview__image-container {
    height: 200px;
  }

  .nft-preview__info-column {
    text-align: center;
    padding: 0;
  }

  .nft-preview__title {
    font-size: 1.25rem;
  }

  .nft-preview__subtitle {
    margin-bottom: 1.25rem;
    font-size: 0.9rem;
  }

  .nft-preview__text {
    font-size: 0.9rem;
  }

  .nft-preview__link svg {
    margin-left: 6px;
  }

  .nft-preview__image-column {
    padding: 0 0 1rem;
  }
}

/* NFTTributeProposalCard */

.nft-proposalcard {
  @extend .proposalcard;

  padding: 2rem;

  .votingstatus-container {
    margin-top: 1rem;
  }

  .votingbar-container {
    margin-top: 0.25rem;
  }

  .votingbar__percentages {
    margin: 0;
    font-size: 0.9rem;

    .yes-percent {
      left: 0;
      color: $color-accent-secondary;
    }

    .no-percent {
      right: 0;
      color: $color-accent-primary;
    }

    .yes-check,
    .no-check {
      display: none;
    }
  }

  .votingbar {
    display: none;
  }
}

.nft-proposalcard__title {
  @extend .proposalcard__title;

  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    18px
  ); /* min font-size 1rem, max 1.125rem */
  margin: 1rem 0 0.5rem;
  line-height: 1.4;
}

.nft-proposalcard__subtitle {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    13px,
    14px
  ); /* min font-size 0.8125rem, max 0.875rem */
  line-height: 1.25;
  text-align: center;
  font-weight: 300;
}

.nft-proposalcard__image-container {
  @extend %image-container;

  height: 275px;

  .thumb {
    @extend %thumb;

    .lazyload-wrapper {
      display: inline-flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .preview-file {
    @extend %preview-file;
  }

  .no-image-available {
    @extend %no-image-available;

    font-size: 0.8rem;
  }
}

.nft-proposalcard__link {
  text-decoration: none;
}

@media only screen and (max-width: em($bp-sm)) {
  .nft-proposalcard__image-container {
    height: 200px;
  }

  .nft-proposalcard .votingbar__percentages {
    font-size: 13px;
  }
}

/* NFTTributeProposalDetails */

.nft-proposaldetails__content {
  @extend .proposaldetails__content;

  // content title
  .nft-proposaldetails__title {
    margin-bottom: 0.75rem;

    .nft-preview__link {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 2rem;
    }
  }

  // content subtitle
  .nft-proposaldetails__subtitle {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      14px,
      16px
    ); /* min font-size 0.875rem, max 1rem */
  }
}

.nft-proposaldetails__body {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    18px
  ); /* min font-size 1rem, max 1.125rem */
  line-height: 1.3;
}

.nft-proposaldetails__nft-wrapper {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 1rem 0;
  padding: 1rem;
  align-items: center;
  min-height: 10rem;
  justify-content: center;
}

.nft-proposaldetails__image-container {
  @extend %image-container;

  height: 350px;
  width: 100%;

  .thumb {
    @extend %thumb;
  }

  .preview-file {
    @extend %preview-file;
  }

  .no-image-available {
    @extend %no-image-available;

    font-size: 0.8rem;
  }
}

.nft-proposaldetails__applicant-info {
  word-wrap: break-word;
  margin-top: 5rem;
  text-align: center;
}

.nft-proposaldetails__terms-checkbox {
  max-width: 20rem;
  margin: 0 auto 1rem;

  .checkbox-text {
    opacity: initial;
  }
}

.nft-proposaldetails__text {
  width: 100%;
  margin: 0 auto 1rem;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    14px,
    16px
  ); /* min font-size 0.875rem, max 1rem */
  line-height: 1.25;
  text-align: justify;

  @include size-medium-down {
    max-width: 20rem;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .nft-proposaldetails__body {
    margin-bottom: 1rem;
  }

  .nft-proposaldetails__nft-wrapper {
    padding: 0;
  }

  .nft-proposaldetails__image-container {
    height: 275px;
  }

  .nft-proposaldetails__applicant-info {
    margin-top: 2rem;
  }

  .nft-proposaldetails__content .nft-proposaldetails__title .nft-preview__link {
    margin-left: 1rem;

    > span:first-child {
      display: none;
    }

    svg {
      margin-left: 0;
    }
  }

  .nft-proposaldetails__terms-checkbox .checkbox-text {
    font-size: 14px;
  }
}

/* NFTCard */

.nft-card {
  @extend .proposalcard;

  padding: 1rem 1rem 2rem;
}

.nft-card__title {
  @extend .proposalcard__title;

  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    18px
  ); /* min font-size 1rem, max 1.125rem */
  margin: 1rem 0 0.5rem;
  line-height: 1.4;
}

.nft-card__subtitle {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    13px,
    14px
  ); /* min font-size 0.8125rem, max 0.875rem */
  line-height: 1.25;
  text-align: center;
  font-weight: 300;
}

.nft-card__image-container {
  @extend %image-container;

  height: 275px;

  .thumb {
    @extend %thumb;

    .lazyload-wrapper {
      display: inline-flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .preview-file {
    @extend %preview-file;
  }

  .no-image-available {
    @extend %no-image-available;

    font-size: 0.8rem;
  }
}

.grid__cards--small .nft-card__image-container {
  height: 200px;
}

@media only screen and (max-width: em($bp-sm)) {
  .nft-card__image-container {
    height: 225px;
  }

  .grid__cards--small .nft-card__image-container {
    height: 150px;
  }
}

/* NFTDetailsModal */

.nft-details__modal {
  .nft-details__nav-container {
    margin-top: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80vh;
  }

  .modal {
    flex: 0 1 70%;
    min-width: 70%;
    max-width: 32.5rem;
    margin: 0 6rem;
    border-color: $color-border-secondary;
  }

  .nft-details__nav-button {
    color: $color-accent-secondary;
    cursor: pointer;
    flex: 0 1 15%;

    svg {
      width: 60px;
      height: 60px;
    }
  }
}

.nft-details__nft-wrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  flex-direction: column;

  .nft-card__image-container {
    height: 400px;
    width: 100%;
  }

  .nft-preview__link {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      14px,
      15px
    ); /* min font-size 0.875rem, max 0.9375rem */
    margin-top: 2rem;

    svg {
      margin-left: 6px;
    }
  }
}

@media only screen and (max-width: em($bp-md)) {
  .nft-details__modal {
    .nft-details__nav-container {
      flex-direction: column;
    }

    .modal {
      margin: 2.5rem 0;
    }

    .nft-details__nav-button {
      transform: rotate(90deg);

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .nft-details__modal {
    .modal {
      margin: 1.5rem 0;
    }

    .nft-details__nav-button {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .nft-details__nft-wrapper {
    .nft-card__image-container {
      height: 250px;
    }

    .nft-preview__link {
      margin-top: 1rem;
    }
  }
}

/* OwnedNFTsModal */

.owned-nfts__modal {
  .modal {
    max-width: none;
  }
}

/* NFTMemberTermsModal */

.nft-terms__modal .modal {
  margin-top: 5vh;
}

.nft-terms__text {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    14px,
    18px
  ); /* min font-size 0.875rem, max 1.125rem */
  line-height: 1.3;
  text-align: initial;
  text-transform: uppercase;
  margin: 0 0 1rem;
}

.nft-terms__list {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    14px,
    18px
  ); /* min font-size 0.875rem, max 1.125rem */
  margin: 0;
  padding-left: 1rem;

  li {
    text-align: initial;
    line-height: 1.3;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: em($bp-md)) {
  .nft-terms__text,
  .nft-terms__list li {
    text-align: justify;
  }
}
