@use "./variables" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */
@use "./button";

.modal-container {
  height: calc(100% - (1.5rem * 2)); /* minus the top, bottom margins */
  margin: 1.5rem auto;
  max-width: 34.5rem;
  outline: none;
}

.modal-container--lg {
  @extend .modal-container;

  max-width: 45rem;
}

.modal-container--xl {
  @extend .modal-container;

  max-width: 75rem;
}

.modal-overlay {
  background: rgba(248, 250, 253, 0.8);
  border: none;
  left: 0;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.modal {
  margin: 8vh auto 0;
  position: relative;
  text-align: center;
  max-width: 52.5rem;
  max-height: calc(
    100vh - 10rem
  ); /* dynamic height, minus any surrounding margin + padding */
  background-color: $color-background-primary;
  border-radius: 15px;
  border: 2px solid $color-border-primary;
  padding: 2rem 1rem;
  overflow-y: scroll;

  @media only screen and (min-width: em($bp-sm)) {
    padding: 2rem 3.6rem;
  }
}

.modal__title {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    18px,
    24px
  ); /* min font-size 1.125rem, max 1.5rem */
  letter-spacing: 1px;
  font-weight: 500;
}

.modal__subtitle {
  font-size: 1.125rem;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    18px
  ); /* min font-size 1rem, max 1.125rem */
  margin-bottom: 1.5rem;
}

.modal__close-button {
  @extend %button-base;

  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: $color-primary;
  width: 15px;
}

.modal__close-button--icon {
  width: 1.75rem;
}
