@use "./variables" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */
@use "./button";

.proposalcard {
  background-color: $color-background-primary;
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  border: 1px solid $color-border-secondary;

  &:hover {
    cursor: pointer;
  }
}

.proposalcard__title {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    20px
  ); /* min font-size 1rem, max 1.25rem */
  line-height: 1.6;
  text-align: center;
  font-weight: normal;

  &:last-child {
    margin: 0;
  }
}

.proposalcard__button {
  @extend .button--secondary;

  @include fluid-type(
    $bp-sm,
    $bp-xl,
    16px,
    18px
  ); /* min font-size 1rem, max 1.125rem */
  width: 100%;
  margin-top: 1.5rem;
}

.proposalcard {
  .votingstatus-container {
    margin-top: 3.5rem;
    // hide element that is only displayed in the proposal details view
    svg {
      display: none;
    }
  }
}

.proposalcard__link {
  text-decoration: none;
}
