// font stacks
$font-system-sans: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-logo: Arial, sans-serif;
$font-logo-accent: "Roboto Mono", monospace;
$font-body: Arial, sans-serif;
$font-header: Arial, sans-serif;
$font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace;
$font-serif: "Times New Roman", Times, "Georgia", serif;

// widths
$width-wide: 1200px;
$width-max-content: 1125px;

// colors
$color-babyblue: #6ef4fa;
$color-babypink: #e083f6;
$color-brightlime: #75f86a;
$color-brightsalmon: #ff6f6f;
$color-black: #000000;
$color-cirrus: #c5ddef;
$color-darksmoke: #333333;
$color-deepsmoke: #212121;
$color-fadedsky: #5da8d2;
$color-hotpink: #e83adc;
$color-keylargo: #31cde7;
$color-lightgreen: #83e5a4;
$color-lilac: #4952a5;
$color-litesmoke: #939393;
$color-offwhite: #fafafa;
$color-purple: #742f9e;
$color-royal: #076ad2;
$color-sunny: #f5f650;
$color-wiltedlilac: #828092;

$color-primary: #4e4e4e;
$color-primary-hover: #f15e4a;
$color-secondary: #4d9aac;
$color-accent-primary: #f15e4a;
$color-accent-secondary: #4f96a6;
$color-accent-tertiary: #00eeff;
$color-background-page: #ffffff;
$color-background-primary: #ffffff;
$color-background-secondary: #f4f4f4;
$color-border-primary: #d3d3d3;
$color-border-secondary: #e3e1e1;
$color-border-tertiary: #e2e2e2;
$color-shadow-primary: #d3d3d3;
$color-shadow-secondary: #000000;
$color-button-primary: #ffffff;
$color-button-background-primary: #65a7b6;
$color-button-secondary: #5397a7;
$color-button-background-secondary: #ffffff;
$color-landing-text: #ffffff;
$color-landing-hover: #4e4e4e;

$colors: (
  "babyblue": $color-babyblue,
  "babypink": $color-babypink,
  "brightlime": $color-brightlime,
  "brightsalmon": $color-brightsalmon,
  "black": $color-black,
  "cirrus": $color-cirrus,
  "darksmoke": $color-darksmoke,
  "deepsmoke": $color-deepsmoke,
  "fadedsky": $color-fadedsky,
  "hotpink": $color-hotpink,
  "keylargo": $color-keylargo,
  "lightgreen": $color-lightgreen,
  "lilac": $color-lilac,
  "litesmoke": $color-litesmoke,
  "offwhite": $color-offwhite,
  "purple": $color-purple,
  "royal": $color-royal,
  "sunny": $color-sunny,
  "wiltedlilac": $color-wiltedlilac,
);

// breakpoints (via bootstrap)
$bp-sm: 576px !default;
$bp-md: 768px !default;
$bp-lg: 992px !default;
$bp-xl: 1200px !default;
