@use "./variables" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */

.logo-container {
  padding: 1rem 3rem;
  z-index: 0;

  &--header {
    padding: 0 2rem;
  }

  &--center {
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
}

.logo {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    96px
  ); /* min font-size 4rem, max 6rem */

  color: $color-accent-primary;
  font-size: 6em;
  font-weight: 700;
  letter-spacing: 6px;
  font-family: $font-logo;

  .logo__last-character {
    font-family: $font-logo-accent;
  }

  &--small {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      30px,
      36px
    ); /* min font-size 1.875rem, max 2.25rem */
  }

  &--medium {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      30px,
      50px
    ); /* min font-size 1.875rem, max 3.125rem */
  }

  &--large {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      52px,
      84px
    ); /* min font-size 3.25rem, max 5.25rem */
  }
}

.muse0-text {
  text-transform: uppercase;

  span:last-child {
    font-family: $font-logo-accent;
  }
}
