@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

// https://css-tricks.com/snippets/css/typewriter-effect/
.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid $color-landing-text; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 2.5s steps(40, end), blink-caret 0.75s step-end infinite,
    hide-caret 2.5s step-end forwards;

  @media only screen and (max-width: em($bp-sm)) {
    font-size: 0.8rem;
  }
}

.typewriter--rotated {
  @extend .typewriter;

  animation: typing-rotated 2.5s steps(40, end) 3.5s backwards,
    blink-caret 0.75s step-end 3s infinite,
    hide-caret 2.5s step-end 3s reverse backwards;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes typing-rotated {
  from {
    width: 0;
  }
  to {
    width: 368px;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $color-landing-text;
  }
}

@keyframes hide-caret {
  from {
    border-right-width: 0.15em;
  }
  to {
    border-right-width: 0;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .typewriter {
    font-size: 0.8rem;
  }

  @keyframes typing-rotated {
    from {
      width: 0;
    }
    to {
      width: 246px;
    }
  }
}
