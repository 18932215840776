@use "sass:math";

@use "./variables" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */

// https://codepen.io/lbebber/pen/ypgql
.glitch {
  position: relative;
  width: 315px;

  @include size-medium-down {
    width: 130px;
  }
}

@keyframes noise-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(math.div(1, $steps)))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}
.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 0px;
  text-shadow: -1px 0 $color-accent-tertiary;
  top: 10px;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;

  @include size-medium-down {
    top: 4px;
  }
}

@keyframes noise-anim-2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(math.div(1, $steps)))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}

.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 black;
  top: 8px;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;

  @include size-medium-down {
    top: 4px;
  }
}

.landing-wrapper {
  .glitch {
    margin: 0 auto;

    @include size-medium-down {
      width: 205px;
    }
  }
}

.nav-modal {
  .glitch {
    width: 150px;
    margin: 0 auto;

    @include size-medium-down {
      width: 128px;
    }
  }
  .glitch:after {
    top: 4px;
  }
  .glitch:before {
    top: 4px;
  }
}
